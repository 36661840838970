
import { defineComponent, SetupContext, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { addDays, format } from 'date-fns';

export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'changeTab',
    'navigateToSelectTenure',
    'navigateToInvestmentDetails',
    'createInvestment',
  ],
  setup: (props, { emit }: SetupContext) => {
    const { investment }: any = toRefs(props);

    const { state } = useStore();

    const investmentBreakdown = computed(() => {
      const tenure: any = state?.utils?.tenures?.find(
        (prop: any) => prop?.id === investment.value?.tenure_id,
      );

      const amount = investment.value?.amount;

      const potentialEarnings =
        amount * (tenure?.percentage / 100) * (tenure?.days / 365);

      return {
        potential_earnings: potentialEarnings || 0,
        amount: amount,
        interest_rate: tenure?.percentage || 0,
        maturity_date: format(
          addDays(new Date(), tenure?.days ?? 30),
          'do MMMM, yyyy',
        ),
      };
    });

    const back = () => emit('changeTab', 'select');

    const navigateToPaymentMethod = () => emit('changeTab', 'payment-method');

    return {
      back,
      navigateToPaymentMethod,
      investmentBreakdown,
    };
  },
});

<template>
  <div class="inline-flex items-center mb-3 cursor-pointer" @click="back">
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <div class="text-2xl font-bold text-invest-primary">Investment Details</div>
  <div class="mt-5">
    <div class="investment-details">
      <p class="text-xs">Principal amount</p>
      <span class="text-xs text-invest-primary">
        {{ $filters.formatAmount(investmentBreakdown.amount) }}
      </span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Interest rate</p>
      <span class="text-xs text-invest-primary">
        {{ investmentBreakdown.interest_rate }}% annually
      </span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Potential earnings</p>
      <span class="text-xs text-invest-primary">{{
        $filters.formatAmount(investmentBreakdown.potential_earnings)
      }}</span>
    </div>

    <div class="investment-details">
      <p class="text-xs">Maturity date</p>
      <span class="text-xs text-invest-primary">
        {{ investmentBreakdown.maturity_date }}
      </span>
    </div>

    <div class="grid grid-cols-1 mt-40 place-content-end">
      <button
        type="button"
        class="btn btn-primary"
        @click="navigateToPaymentMethod"
      >
        Proceed
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, SetupContext, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { addDays, format } from 'date-fns';

export default defineComponent({
  inheritAttrs: false,
  props: {
    investment: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    'changeTab',
    'navigateToSelectTenure',
    'navigateToInvestmentDetails',
    'createInvestment',
  ],
  setup: (props, { emit }: SetupContext) => {
    const { investment }: any = toRefs(props);

    const { state } = useStore();

    const investmentBreakdown = computed(() => {
      const tenure: any = state?.utils?.tenures?.find(
        (prop: any) => prop?.id === investment.value?.tenure_id,
      );

      const amount = investment.value?.amount;

      const potentialEarnings =
        amount * (tenure?.percentage / 100) * (tenure?.days / 365);

      return {
        potential_earnings: potentialEarnings || 0,
        amount: amount,
        interest_rate: tenure?.percentage || 0,
        maturity_date: format(
          addDays(new Date(), tenure?.days ?? 30),
          'do MMMM, yyyy',
        ),
      };
    });

    const back = () => emit('changeTab', 'select');

    const navigateToPaymentMethod = () => emit('changeTab', 'payment-method');

    return {
      back,
      navigateToPaymentMethod,
      investmentBreakdown,
    };
  },
});
</script>
